import { render, staticRenderFns } from "./IG.vue?vue&type=template&id=6164e142&scoped=true&"
import script from "./IG.vue?vue&type=script&lang=js&"
export * from "./IG.vue?vue&type=script&lang=js&"
import style0 from "./IG.vue?vue&type=style&index=0&id=6164e142&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6164e142",
  null
  
)

export default component.exports